import React, { useRef, useEffect, useState } from "react";
import { translate as t } from "../../../translations/translations";
import "./calculator.css";
import switchImage from "../images/frame.svg";
import tetherImage from "../images/trc-20.svg";
import arrow from "../images/arrow.svg";
import {getRateWithMarkup} from "../../../rates/getRates";

interface CalculatorProps {
  exchangeDirection: {
    name: string;
    image: string;
    shortName: string;
    symbol: string;
  };
  exchangeDirectionsArr: {
    name: string;
    image: string;
    shortName: string;
    symbol: string;
  }[];
  setExchangeDirection: React.Dispatch<
      React.SetStateAction<{
        name: string;
        image: string;
        shortName: string;
        symbol: string;
      }>
  >;
}

function Calculator({
                      exchangeDirection,
                      exchangeDirectionsArr,
                      setExchangeDirection,
                    }: CalculatorProps) {
  const [isIndividual, setIndividual] = useState(true);
  const [isGiveSelectOpen, setGiveSelectOpen] = useState(false);
  const [giveDirection, setGiveDirection] = useState(exchangeDirection);
  const [isGetSelectOpen, setGetSelectOpen] = useState(false);
  const giveWrapperRef = useRef<HTMLDivElement>(null);
  const getWrapperRef = useRef<HTMLDivElement>(null);
  const [getDirection, setGetDirection] = useState({
    name: "Tether TRC-20 USDT",
    image: tetherImage,
    shortName: "TRC USD",
    symbol: "USDT",
  });
  const [amountToGive, setAmountToGive] = useState<number | string>("");
  const [amountToReceive, setAmountToReceive] = useState<number | string>("");
  const [lastExchangeRate, setLastExchangeRate] = useState<number | null>(null);
  const [lastDirections, setLastDirections] = useState<{ from: string; to: string } | null>(null);
  const lang = localStorage.getItem("coinxLang");
  const fetchExchangeRate = async (from: string, to: string) => {
    const response = await fetch(
        `https://core.aldandev.com/template/rates/b2broker/${from}/${to}/`
    );
    const data = await response.json();
    return data;
  };
  // useEffect(() => {
  //   if(giveDirection.shortName == 'RUB' && lastExchangeRate){
  //     setLastExchangeRate(prev => prev * 1.08)
  //   }
  // }, [giveDirection, getDirection,lastExchangeRate]);
  const handleSwitch = () => {
    const newGetDirection = giveDirection;
    const newGiveDirection = getDirection;
    setGetDirection(newGetDirection);
    setGiveDirection(newGiveDirection);
    setExchangeDirection(newGiveDirection);
    setAmountToGive(amountToReceive);
    setAmountToReceive(amountToGive);
  };

  const updateAmountToReceive = async () => {
    if (amountToGive && Number(amountToGive) > 0) {
      if (
          lastDirections &&
          lastDirections.from === giveDirection.symbol &&
          lastDirections.to === getDirection.symbol &&
          lastExchangeRate !== null
      ) {
        setAmountToReceive(Number(amountToGive) * lastExchangeRate);
      } else {
        const rateData = await fetchExchangeRate(
            giveDirection.symbol,
            getDirection.symbol
        );
        const rate = getRateWithMarkup(giveDirection.symbol, getDirection.symbol) * +rateData.value;

        setLastExchangeRate(rate)

        setLastDirections({ from: giveDirection.shortName, to: getDirection.shortName });
        setAmountToReceive(Number(amountToGive) * rate);
      }
    } else {
      setAmountToReceive(0);
    }
  };

  useEffect(() => {
    if (isGetSelectOpen && isGiveSelectOpen) {
      setGetSelectOpen(false);
    }
  }, [isGiveSelectOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
          giveWrapperRef.current &&
          !giveWrapperRef.current.contains(event.target as Node) &&
          getWrapperRef.current &&
          !getWrapperRef.current.contains(event.target as Node)
      ) {
        setGiveSelectOpen(false);
        setGetSelectOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (giveDirection !== exchangeDirection) {
      setGiveDirection(exchangeDirection);
    }
  }, [exchangeDirection]);

  useEffect(() => {
    updateAmountToReceive();
  }, [amountToGive, giveDirection, getDirection]);
  useEffect(() => {
    setLastExchangeRate(null);
  }, [giveDirection, getDirection]);

  return (
      <div className="exchange-calc">
        <div className="calc-header">
          <button
              className={isIndividual ? "calc-header-active" : ""}
              onClick={() => setIndividual(true)}
          >
            <span>{t("individuals")}</span>
          </button>
          <button
              className={isIndividual ? "" : "calc-header-active"}
              onClick={() => setIndividual(false)}
          >
            <span>{t("entities")}</span>
          </button>
        </div>
        <div id="exchange-text">{t("crypto-exchange")}</div>
        <div className="calc-inputs">
          <div className="calc-give-wrapper">
            <span>{t("give")}</span>
            <input
                type="number"
                className="calc-input give-input"
                placeholder={t("enter-your-price") as string}
                value={amountToGive}
                onChange={(e) => setAmountToGive(e.target.value)}
            />
            <div className="select-direction-wrapper" ref={giveWrapperRef}>
              <div
                  className={`exchange-direction ${
                      isGiveSelectOpen
                          ? `exchange-selected-direction-opened`
                          : `exchange-selected-direction`
                  }`}
                  onClick={() => {
                    setGiveSelectOpen((prev) => !prev);
                  }}
              >
                <img className="direction-pic" src={giveDirection.image} />
                <div className="direction-name">{giveDirection.shortName}</div>
                <img className="arrow" src={arrow} />
              </div>
              {isGiveSelectOpen ? (
                  <div className="give-directions">
                    {exchangeDirectionsArr
                        .filter(
                            (dir) =>
                                dir.name !== getDirection.name &&
                                dir.name !== giveDirection.name
                        )
                        .map((direction) => (
                            <div
                                className="exchange-direction"
                                onClick={() => {
                                  setGiveSelectOpen(false);
                                  setExchangeDirection(direction);
                                }}
                            >
                              <img className="direction-pic" src={direction.image} />
                              <div className="direction-name">{direction.shortName}</div>
                            </div>
                        ))}
                  </div>
              ) : null}
            </div>
          </div>
          <button onClick={() => handleSwitch()} className="exchange-switch">
            {/*<img src={switchImage} alt="Switch" />*/}
          </button>

          <div className="calc-get-wrapper">
            <span>{t("receive")}</span>
            <div className="calc-input receive-field">{lastExchangeRate ? Number(amountToGive) * lastExchangeRate : 0}</div>
            <div className="select-direction-wrapper" ref={getWrapperRef}>
              <div
                  className={`exchange-direction ${
                      isGetSelectOpen
                          ? `exchange-selected-direction-opened`
                          : `exchange-selected-direction`
                  }`}
                  onClick={() => {
                    setGetSelectOpen((prev) => !prev);
                  }}
              >
                <img className="direction-pic" src={getDirection.image} />
                <div className="direction-name">{getDirection.shortName}</div>
                <img className="arrow" src={arrow} />
              </div>
              {isGetSelectOpen ? (
                  <div className="give-directions">
                    {exchangeDirectionsArr
                        .filter(
                            (dir) =>
                                dir.name !== getDirection.name &&
                                dir.name !== giveDirection.name
                        )
                        .map((direction) => (
                            <div
                                className="exchange-direction"
                                onClick={() => {
                                  setGetSelectOpen(false);
                                  setGetDirection(direction);
                                }}
                            >
                              <img className="direction-pic" src={direction.image} />
                              <div className="direction-name">{direction.shortName}</div>
                            </div>
                        ))}
                  </div>
              ) : null}
            </div>
          </div>
        </div>
        {lastExchangeRate && <div className="exchange-rate">{Number(lastExchangeRate) > 1 ? `
          1 ${giveDirection.shortName} ${amountToGive === amountToReceive ? '=' : '≈'}  
          ${lastExchangeRate && Number(lastExchangeRate.toFixed(6)).toString()} ${getDirection.shortName}
        ` :
            `
          1 ${getDirection.shortName} ${amountToGive === amountToReceive ? '=' : '≈'}  
          ${lastExchangeRate && Number((1 / lastExchangeRate).toFixed(2)).toString()} ${giveDirection.shortName}
        `
        }</div> }
        <button onClick={() => {
          window.location.href = `https://my.coinx.kg/${lang}/auth/sign-up`;
        }} className="exchange-now-button">
          <div  className="exchange-now-button-content">{t("exchange-now")}</div>
        </button>
      </div>
  );
}

export default Calculator;
