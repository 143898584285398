import {useEffect, useState} from "react";
import "./App.css";
import Header from "./blocks/Header/header";
import Exchange from "./blocks/Exchange/exchange";
import Advantages from "./blocks/Advanteges/advantages";
import FAQ from "./blocks/FAQ/faq";
import Partners from "./blocks/Partners/partners";
import Footer from "./blocks/Footer/footer";

function App() {
  const [language, setLanguage] = useState<string>('ru');
  const setLang = (lang: string) => {
    localStorage.setItem("coinxLang", lang.toLowerCase());
    setLanguage(lang.toLowerCase());
  };
  useEffect(() => {
    const lang = localStorage.getItem("coinxLang");
    if(lang){
      setLanguage(lang);
    }
    else{
      localStorage.setItem("coinxLang", 'ru');
    }
  }, []);
  return (
    <div className="App">
      <Header setLang={setLang} />
      <div className="main">
        <Exchange />
        <Advantages />
        <FAQ />
        <Partners />
        <Footer />
      </div>
    </div>
  );
}

export default App;
