import React, { useState, useEffect, useRef } from "react";
import { translate as t } from "../../translations/translations";
import "./header.css";
import coinxImage from "./images/coinx.svg";
import vector from "./images/vector.svg";
import selectIcon from "./images/select.svg";
import burgerIcon from "./images/burger.svg";
import closeIcon from "./images/close-btn.svg";
import pdfImg from "./images/pdf.svg";

type Language = "ru" | "en" | "kg";

function isValidLanguage(lang: any): lang is Language {
  return ["ru", "en", "kg"].includes(lang);
}

interface MainProps {
  setLang: (lang: string) => void;
}

interface SelectedLang {
  shortName: string;
  name: string;
  pic: string;
}

const enPic = "https://vectorflags.s3.amazonaws.com/flags/us-circle-01.png";
const kgPic = "https://vectorflags.s3.amazonaws.com/flags/kg-circle-01.png";
const ruPic = "https://vectorflags.s3.amazonaws.com/flags/ru-circle-01.png";

const langsObj = {
  ru: { name: "Russian", pic: ruPic, shortName: "RU" },
  en: { name: "English", pic: enPic, shortName: "EN" },
  kg: { name: "Kyrgyz", pic: kgPic, shortName: "KG" }
};

function Header({ setLang }: MainProps) {
  const [isSelectLangOpen, setSelectLangOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("exchange");
  const selectWrapperRef = useRef<HTMLDivElement>(null);

  let initialLang = localStorage.getItem("coinxLang") as Language | null;
  if (!initialLang || !isValidLanguage(initialLang)) {
    initialLang = "ru";
  }

  const [selectedLang, selectLang] = useState(langsObj[initialLang]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? "auto" : "hidden";
  };

  const signInLink = `https://my.coinx.kg/${selectedLang.shortName.toLowerCase()}/login`;
  const signUpLink = `https://my.coinx.kg/${selectedLang.shortName.toLowerCase()}/register`;
  const onChangeLang = (selectedLang: SelectedLang) => {
    selectLang(selectedLang);
    setSelectLangOpen(false);
    setLang(selectedLang.shortName.toLowerCase());
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 800) {
        setActiveLink("exchange");
      } else if (window.scrollY >= 800 && window.scrollY < 1900) {
        setActiveLink("advantages");
      } else if (window.scrollY >= 1900) {
        setActiveLink("faq");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectWrapperRef.current &&
        !selectWrapperRef.current.contains(event.target as Node)
      ) {
        setSelectLangOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="header-main">
      <div className="header">
        <div className="header-logo-container">
          <img src={burgerIcon} id="burger-icon" onClick={toggleMenu} />
          <img
            className="header-logo"
            src={coinxImage}
            alt="coinx"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />
        </div>

        <div className={`burger-menu ${isMenuOpen ? "burger-menu-open" : ""}`}>
          <div className="burger-menu-header">
            <img
                className=""
                src={coinxImage}
                alt="coinx"
                onClick={() => {
                  window.scrollTo({top: 0});
                  toggleMenu();
                }}
            />
            <button className="close-menu" onClick={toggleMenu}>
              <img src={closeIcon}/>
            </button>
          </div>

          <ul className="burger-menu-list">
            <li
                onClick={() => {
                  toggleMenu();
                  window.scrollTo({top: 200, behavior: "smooth"});
                }}
            >
              <div>{t("exchange")}</div>
            </li>
            <li
                onClick={() => {
                  toggleMenu();
                  window.scrollTo({top: 1070, behavior: "smooth"});
                }}
            >
              <div>{t("advantages")}</div>
            </li>
            <li
                onClick={() => {
                  toggleMenu();
                  window.scrollTo({top: 2700, behavior: "smooth"});
                }}
            >
              <div>{t("faq")}</div>
            </li>
          </ul>

          <a href={signInLink} className="menu-sign-in" onClick={toggleMenu}>
            {t("sign-in")}
          </a>

          <a href={signUpLink} className="menu-sign-up" onClick={toggleMenu}>
            {t("signUp")}
          </a>
          <div className="menu-phone">
            <span className="phone-text">{t("phone")}</span>
            <span className="phone-number"> +996 (997) 777 500</span>
          </div>
          <div className="menu-email">
            <span className="email-text">{t("email")}</span>
            <span className="email-email"> team@coinx.kg </span>
          </div>
          <a
              href="https://drive.google.com/uc?export=download&id=1yKs-v-8xmFiYL073e5K2tlztKUbBR2SS"
              className="menu-license"
          >
            {" "}
            {t("legal-info-rows")[5]}
            <img src={pdfImg} id="pdf"/>
          </a>
          <div
              className={`select-wrapper ${
                  isSelectLangOpen ? "lang-select-open" : ""
              }`}
          >
            <button
                className="lang selected-lang"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectLangOpen((prev) => !prev);
                }}
            >
              <img className="lang-pic" src={selectedLang.pic}/>
              <div className="lang-name">{selectedLang.shortName}</div>
              <img
                  className={`lang-vector ${isSelectLangOpen ? "rotated" : ""}`}
                  src={vector}
                  alt="Toggle language selection"
              />
            </button>
            {isSelectLangOpen ? (
                <div className="langs-select">
                  {Object.values(langsObj).map((lang) =>
                      lang.name === selectedLang.name ? (
                          <button
                              className="langs-select-selected"
                              key={lang.name}
                              onClick={() => {
                                setSelectLangOpen(false);
                                onChangeLang(lang);
                              }}
                          >
                            <div className="lang-name">{lang.name}</div>
                            <img className="select-icon" src={selectIcon}/>
                          </button>
                      ) : (
                          <button
                              className="select-lang"
                              key={lang.name}
                              onClick={() => {
                                setSelectLangOpen(false);
                                onChangeLang(lang);
                              }}
                          >
                            <div className="lang-name">{lang.name}</div>
                          </button>
                      )
                  )}
                </div>
            ) : null}
          </div>
        </div>

        <div className="links">
          <button
              id={activeLink === 'advantages' || activeLink === 'faq' ? 'link-inactive' : ''}
              className={activeLink === "exchange" ? "link active" : "link"}
              onClick={() => {
                window.scrollTo({top: 0, behavior: "smooth"});
              }}
          >
            {t("exchange")}
          </button>
          <button
              id={activeLink === 'faq' ? 'link-inactive' : ''}
              className={activeLink === "advantages" ? "link active" : "link"}
              onClick={() => {
                window.scrollTo({top: 870, behavior: "smooth"});
              }}
          >
            {t("advantages")}
          </button>
          <button
              className={activeLink === "faq" ? "link active" : "link"}
              onClick={() => {
                window.scrollTo({top: 2100, behavior: "smooth"});
              }}
          >
            {t("faq")}
          </button>
        </div>
        <div className="header-right">
          <div
              className={`select-wrapper ${
                  isSelectLangOpen ? "lang-select-open" : ""
              }`}
              ref={selectWrapperRef}
          >
            <button
                className="lang selected-lang"
                onClick={() => setSelectLangOpen((prev) => !prev)}
            >
              <img className="lang-pic" src={selectedLang.pic}/>
              <div className="lang-name">{selectedLang.shortName}</div>
              <img
                  className={`lang-vector ${isSelectLangOpen ? "rotated" : ""}`}
                  src={vector}
                  alt="Toggle language selection"
              />
            </button>
            {isSelectLangOpen ? (
                <div className="langs-select">
                  {Object.values(langsObj).map((lang) =>
                      lang.name === selectedLang.name ? (
                          <button
                              className="langs-select-selected"
                              key={lang.name}
                              onClick={() => onChangeLang(lang)}
                          >
                            <div className="lang-name">{lang.name}</div>
                      <img className="select-icon" src={selectIcon} />
                    </button>
                  ) : (
                    <button
                      className="select-lang"
                      key={lang.name}
                      onClick={() => onChangeLang(lang)}
                    >
                      <div className="lang-name">{lang.name}</div>
                    </button>
                  )
                )}
              </div>
            ) : null}
          </div>
          <a href={signUpLink} className="sign-up">
            {t("signUp")}
          </a>
          <a href={signInLink} className="sign-in">
            {t("signIn")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
