import React, { useState } from "react";
import {
  translate as t,
  TranslationKeys
} from "../../translations/translations";
import "./faq.css";
import openIcon from "./Icon.svg";

interface FAQItemProps {
  question: TranslationKeys;
  answer: TranslationKeys;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <li className={isOpen ? "answer-opened" : "answer-closed"}>
      <button className="question" onClick={toggle} aria-expanded={isOpen}>
        <div className="question-text"> {t(question)}</div>

        <img
          className="answer-open-icon"
          src={openIcon}
          alt="Toggle answer visibility"
        />
      </button>
        {isOpen && <div className="answer">{
            answer === 'verification-needed-answer' ?
            <>
                <span>{t(answer)[0]}</span>
                <span style={{fontWeight: 'bold'}}>{t(answer)[1]}</span>
                <span>{t(answer)[2]}</span>
            </>
            : t(answer)}</div>
        }
    </li>
  );
};

const FAQ: React.FC = () => {
  return (
    <div className="faq">
      <h4 id="questions-text">{t("questions-text")}</h4>
      <h2 id="faq-header">{t("faq")}</h2>
      <ul className="questions-list" aria-labelledby="faq-header">
        <FAQItem
          question="verification-needed-question"
          answer="verification-needed-answer"
        />
        <FAQItem
          question="office-address-question"
          answer="office-address-answer"
        />
        <FAQItem
          question="activation-code-question"
          answer="activation-code-answer"
        />
        <FAQItem
          question="exchange-duration-question"
          answer="exchange-duration-answer"
        />
        <FAQItem question="what-is-aml-question" answer="what-is-aml-answer" />
      </ul>
    </div>
  );
};

export default FAQ;
