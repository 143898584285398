import { useState } from "react";
import { translate as t } from "../../translations/translations";
import "./exchange.css";
import walletImage from "./images/wallet-img.svg";
import pmImage from "./images/pm.svg";
import payeerImage from "./images/payeer.svg";
import tetherImage from "./images/trc-20.svg";
import advImage from "./images/adv.svg";
import btcImage from "./images/btc.svg";

import rubImage from "./images/rub.svg";
import usdImage from "./images/usd.svg";
import usdtEth from "./images/usdt-eth.svg";
import kgsImage from "./images/kgs.svg";
import ethImage from "./images/eth.svg";
import Calculator from "./components/calculator";
function Exchange() {
  const [exchangeDirection, setExchangeDirection] = useState({
    name: "RUB",
    image: rubImage,
    shortName: "RUB",
    symbol: "RUB"
  });
  const exchangeDirectionsArr = [
    { name: "RUB", image: rubImage, shortName: "RUB", symbol: 'RUB' },
    { name: "USD", image: usdImage, shortName: "USD", symbol: 'USD' },
    { name: "KGS", image: kgsImage, shortName: "KGS",  symbol: 'KGS'},
    { name: "Perfect Money USD", image: pmImage, shortName: "PM USD", symbol: 'USD'},
    { name: "Payeer USD", image: payeerImage, shortName: "PAY USD", symbol: 'USD'},
    { name: "ADVcash USD", image: advImage, shortName: "ADV USD", symbol: 'USD' },
    { name: "Tether TRC-20 USDT", image: tetherImage, shortName: "TRC USD", symbol: 'USDT' },
    { name: "Bitcoin BTC", image: btcImage, shortName: "BTC", symbol: 'BTC' },
    { name: "USDT ERC-20", image: usdtEth, shortName: "ERC USD", symbol: 'USDT' },
    { name: "Ethereum", image: ethImage, shortName: "ETH", symbol: 'ETH' }
  ];
  const lang = localStorage.getItem("coinxLang");
  return (
    <div className="exchange-container">
      <div className="exchange">
        <div className="exchange-left">
          <div id={lang === 'en' ? 'big-text-en' : ''} className="exchange-left-text">
            <div>{t("bigText")[0]}</div>
            <div>
              {" "}
              <span> {t("bigText")[1]}</span>
              <span style={{ color: "#C7AB6E" }}> {t("bigText")[2]}</span>
            </div>

            <div> {t("bigText")[3]}</div>
          </div>
          <div className="exchange-directions">
            {exchangeDirectionsArr.map((direction) => (
              <button
                className={
                  exchangeDirection.name === direction.name
                    ? "exchange-direction-selected"
                    : ""
                }
                key={direction.name}
                onClick={() => setExchangeDirection(direction)}
              >
                <img src={direction.image}></img>
                {direction.name}
              </button>
            ))}
          </div>
        </div>

        <div className="exchange-right">
          <img src={walletImage} id="wallet-image" />
          <Calculator
            exchangeDirection={exchangeDirection}
            exchangeDirectionsArr={exchangeDirectionsArr}
            setExchangeDirection={setExchangeDirection}
          />
        </div>
      </div>
    </div>
  );
}

export default Exchange;
