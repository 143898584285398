import { translate as t } from "../../translations/translations";
import "./advantages.css";
import fastExchangeImg from "./images/fast-exchange.svg";
import otcImg from "./images/otc.svg";
import license from "./images/license.svg";
import pureCryptoImg from "./images/pure-crypto.svg";
import downloadImg from "./images/download.svg";
import calcImg from './images/calc-img.svg';
import { useEffect, useState} from "react";


function Advantages() {
  const [isCalcFixed, setIsCalcFixed] = useState(false);
  const lang = localStorage.getItem("coinxLang");
  useEffect(() => {
    const handlleScroll = () => {
      if (window.scrollY <= 954 || window.scrollY >= 3889) {
        setIsCalcFixed(false)
      }
      else{
        setIsCalcFixed(true)
      }
    }
    window.addEventListener("scroll", () => handlleScroll())
    return () => {
      window.removeEventListener("scroll", () => handlleScroll());
    };
  }, []);

  const licenseFileUrl =
    "https://drive.usercontent.google.com/u/0/uc?id=1yKs-v-8xmFiYL073e5K2tlztKUbBR2SS&export=download";
  return (
    <div className="advantages">
      <p id="difference">{t("difference")}</p>
      <span id="advantages">{t("advantages").toString().toLowerCase()}</span>
      <div className="advantages-blocks">
        <div id="fastExchange">
          <div className="block-header">{t("quick-exchange")[0]}</div>
          <span className="block-text">{t("quick-exchange")[1]}</span>
          <img id={lang === 'kg' ? 'kg-fast-ex-img' : ''} src={fastExchangeImg}></img>
        </div>
        <div id="pureCrypto">
          <span className="block-header">{t("pure-crypto")[0]}</span>
          <span className="block-text block-text1">{t("pure-crypto")[1]}</span>
          <span className="block-text block-text2">{t("pure-crypto")[2]}</span>
          <img id={lang === 'kg' ? 'kg-pure-ex-img' : ''} src={pureCryptoImg}></img>
        </div>
        <div id="otc">
          <span className="block-header">{t("otc-service")[0]}</span>
          <span className="block-text">{t("otc-service")[1]}</span>
          <img src={otcImg}></img>
        </div>
        <div id="license">
          <span className="block-header">{t("licensed-exchange")}</span>
          <a href={licenseFileUrl} download="LicenseAgreement.pdf">
            <button id="download-pdf">
              <span>{t("download-pdf")}</span>
              <img src={downloadImg} />
            </button>
          </a>
          <img src={license}></img>
        </div>
      </div>
      <button className={isCalcFixed ? 'calc-btn-fix' : 'calc-btn'} onClick={() => window.scrollTo({top: 0, behavior: "smooth" })} >
        <img src={calcImg}/>
      </button>
    </div>
  );
}

export default Advantages;
