import markups from './markups.json';

interface Markup {
    sell: number;
    buy: number;
}

interface Markups {
    [key: string]: Markup;
}

const markupsData: Markups = markups as Markups;

export const getRateWithMarkup = (sellCurrency: string, buyCurrency: string): number => {
    const sellRate = markupsData[sellCurrency]?.sell;
    const buyRate = markupsData[buyCurrency]?.buy;

    if (!sellRate || !buyRate) {
       return 1
    }

    return 1 - (sellRate + buyRate) / 100 ;
}
