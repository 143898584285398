import { translate as t, isStringArray } from "../../translations/translations";
import coinxLogo from "./images/coinx.svg";
import inLogo from "./images/linkedIn.svg";
import tgLogo from "./images/tg.svg";
import arrowImg from "./images/arrow.svg";
import pdfImg from "../Header/images/pdf.svg";
import "./footer.css";
import {useEffect, useState} from "react";

function Footer() {
  const officeText = t("our-office-text");
  const lang = localStorage.getItem("coinxLang");
  const [isArrowFixed, setIsArrowFixed] = useState(false);
  useEffect(() => {
    const handlleScroll = () => {
      if (window.scrollY <= 4970 ) {
        setIsArrowFixed(false)
      }
      else{
        setIsArrowFixed(true)
      }
    }
    window.addEventListener("scroll", () => handlleScroll());
    return () => {
      window.removeEventListener("scroll", () => handlleScroll());
    };
  }, []);
  return (
    <div className="footer-container">
      <div className="footer">
        <img
          src={coinxLogo}
          className="footer-coinx-logo"
          alt="CoinX Logo"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
        <div className="footer-our-office">
          <span className="footer-column-head">{t("our-office")}</span>
          <div className="footer-column-text">
            {isStringArray(officeText) ? (
              officeText.map((text, index) => <div key={index}>{text}</div>)
            ) : (
              <span>{officeText}</span>
            )}
          </div>
        </div>
        <div className="footer-juridical">
          <span className="footer-column-head">{t("legal-info")[0]}</span>
          <div className="footer-column-text">
            <div>
              <a
                href={`https://drive.google.com/uc?export=download&id=1QTUDpQgHn7PV-2uXBn2T8Xd4FedaCxlb`}
              >
                {t("legal-info-rows")[0]}
              </a>
            </div>
            <div>
              <a
                href={`https://drive.google.com/uc?export=download&id=1EzH7S3uyARyENdsEmYBeWevsbvpnYwSK`}
              >
                {t("legal-info-rows")[1]}
              </a>
            </div>
            <div>
              <a href={`https://coinx.kg/${lang}/refund-policy/#top`}>
                {t("legal-info-rows")[2]}
              </a>
            </div>
            <div>
              <a
                href={`https://drive.google.com/uc?export=download&id=1kUARPS3YHEXUzpBS9uePYiMwdxbdCJYO`}
              >
                {t("legal-info-rows")[3]}
              </a>
            </div>
            <div>
              <a
                href={`https://drive.google.com/uc?export=download&id=15Bi8gWRouX-A-KWPkO89AaH9e1JhMFkJ`}
              >
                {t("legal-info-rows")[4]}
              </a>
            </div>
            <div>
              <a
                href={`https://drive.google.com/uc?export=download&id=1yKs-v-8xmFiYL073e5K2tlztKUbBR2SS`}
              >
                {t("legal-info-rows")[5]}
                <img src={pdfImg} id="pdf" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-info">
          <span className="footer-column-head">{t("legal-info")[1]}</span>
          <div className="footer-column-text">
            <div>
              <a
                onClick={() => {
                  window.scrollTo({ top: 860, behavior: "smooth" });
                }}
              >
                {t("additional-features")[0]}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  window.scrollTo({ top: 860, behavior: "smooth" });
                }}
              >
                {t("additional-features")[1]}
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  window.scrollTo({ top: 3500, behavior: "smooth" });
                }}
              >
                {t("additional-features")[2]}
              </a>
            </div>
          </div>
          <div className="social-media-links">
            <a href="https://www.linkedin.com/company/coin-x/" className="linked-in">
              <img src={inLogo} alt={"LinkedIn"} />
            </a>
            <a href="https://t.me/coinxkg" className="telegram">
              <img src={tgLogo} alt={"Telegram"} />
            </a>
          </div>
        </div>
        <button
          className={isArrowFixed ? 'footer-arrow-fixed' : "footer-arrow"}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img src={arrowImg} />
        </button>
      </div>
    </div>
  );
}

export default Footer;
