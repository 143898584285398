
export type Language = "ru" | "en" | "kg";
export type TranslationKeys = keyof typeof translations;

export function translate(key: TranslationKeys): string | string[] {
  const lang = (localStorage.getItem("coinxLang") || "ru") as Language;
  const result = translations[key][lang];
  return result || "";
}

export function isStringArray(value: any): value is string[] {
  return Array.isArray(value);
}

const translations = {
  exchange: {
    ru: "Обмен",
    en: "Exchange",
    kg: "Алмашуу"
  },
  advantages: {
    ru: "Преимущества",
    en: "Advantages",
    kg: "Артыкчылыктары"
  },
  faq: {
    ru: "FAQ",
    en: "FAQ",
    kg: "FAQ"
  },
  signIn: {
    ru: "Войти",
    en: "Log In",
    kg: "Кириүү"
  },
  signUp: {
    ru: "Регистрация",
    en: "Sign Up",
    kg: "Регистрациялоо"
  },
  bigText: {
    ru: ["Покупай", "и продавай", "USDT", "по лучшему курсу"],
    en: ["Buy", "and sell", "USDT", "at the best rate"],
    kg: ["Эң ыңгайлуу", "багытта", "USDT", "- ни сатып ал жана сат"]
  },
  "exchange-now": {
    ru: "Обменять сейчас",
    en: "Start exchange",
    kg: "Алмаштырууну азыр башта"
  },
  individuals: {
    ru: "Физические лица",
    en: "Personal",
    kg: "Жеке тарап"
  },
  entities: {
    ru: "Юридические лица",
    en: "Business",
    kg: "Юридикалык тарап"
  },
  "exchange-directions": {
    ru: "Вы отдаете. Вы получаете",
    en: "You give. You receive.",
    kg: "Сиз бересиз. Биз кайтарабыз"
  },
  "enter-your-price": {
    ru: "Введите вашу цену",
    en: "Enter your price",
    kg: "Бааңызды киргизиңиз"
  },
  "sign-in": {
    ru: "Войти",
    en: "Sign in",
    kg: "Кириүү"
  },
  "crypto-exchange": {
    ru: "Обмен криптовалюты",
    en: "Crypto exchange",
    kg: "Крипокаражатын алмаштыруу"
  },
  registration: {
    ru: "Регистрация",
    en: "Sign up",
    kg: "Каттоо"
  },
  give: {
    ru: "Вы отдаете",
    en: "You give",
    kg: "Берет"
  },
  receive: {
    ru: "Вы получаете",
    en: "You receive",
    kg: "Алат"
  },
  difference: {
    ru: "(    наше отличие от конкурентов    )",
    en: "(    our difference from competitors    )",
    kg: "(    атаандаштардан айырмабыз    )"
  },
  "quick-exchange": {
    ru: [
      "Быстрый обмен",
      "Максимальная скорость обмена за счет полной автоматизации"
    ],
    en: [
      "Quick exchange",
      "Maximum speed of exchange thanks to full automation"
    ],
    kg: ["Толук автоматташтырган", "түрдө тез алмаштыруу жолдору"]
  },
  "pure-crypto": {
    ru: [
      "Чистая криптовалюта",
      "CoinX гарантирует выпуск прозрачных криптовалют благодаря самым современным инструментам"
    ],
    en: [
      "Pure cryptocurrency",
      "CoinX ensures the issuance"," of transparent cryptocurrencies through the use of the most advanced tools"
    ],
    kg: [
      "CoinX-заманбап ыкмасын негизинде",
      "эң таза криптокаражатын чыгарууга кепилдик берет"
    ]
  },
  "otc-service": {
    ru: [
      "OTC Сервис",
      "При обмене от $10 000 вы работаете с персональным менеджером и получаете низкие комиссионные"
    ],
    en: [
      "OTC Service",
      "When exchanging from $10,000, you work with a personal manager and receive low commissions"
    ],
    kg: [
      "OTC - кызматы",
      "10.000$ доллардан алмаштырууда сиз жеке менеджер менен иштейсиз жана аз комиссияларды аласыз"
    ]
  },
  "licensed-exchange": {
    ru: "Официальный лицензированный криптообменник",
    en: "Official licensed cryptocurrency exchange",
    kg: "Расмий лизензиялаштырылган криптокаратты алмашуу"
  },
  "download-pdf": {
    ru: "Скачать в PDF",
    en: "Download as PDF",
    kg: "Pdfти жүктөнүз"
  },
  "questions-text": {
    ru: "(    о чем нас спрашивают    )",
    en: "(    what they ask us about     )",
    kg: "(    алар бизден эмнени сурашат     )"
  },
  "verification-needed-question": {
    ru: "Зачем нужна верификация аккаунта?",
    en: "Why is account verification necessary?",
    kg: "Эмне үчүн сизге каттоо эсебин текшерүү керек?"
  },
  "verification-needed-answer": {
    ru: ["Верификация пользователя счета, или процедура ","Know Your Customer (KYC),"," предназначена для защиты вашего счета от мошенничества, коррупции, отмывания денег и финансирования терроризма. Для того чтобы получить доступ к услугам CoinX, доступ к выводу средств, все новые пользователи должны пройти верификацию. Ограничения на вывод средств распространяются на существующих пользователей, которые не прошли верификацию."],
    en: ["User account verification, or the"," Know Your Customer (KYC)"," procedure, is designed to protect your account from fraud, corruption, money laundering, and terrorist financing. In order to access CoinX services, including fund withdrawals, all new users must undergo verification. Withdrawal restrictions apply to existing users who have not completed verification."],
    kg: ["Колдонуучунун аккаунтун текшерүү же ,", "Know Your Customer (KYC),"," процедурасы аккаунтуңузду алдамчылыктан, коррупциядан, арам акчаны адалдоодон жана терроризмди каржылоодон коргоо үчүн иштелип чыккан. Coinx кызматтарына жетүү жана акча каражаттарын алуу үчүн бардык жаңы колдонуучулар текшерүүдөн өтүшү керек. алуу чектөөлөрү текшерүүдөн өтпөгөн учурдагы колдонуучуларга тиешелүү"]
  },
  "office-address-question": {
    ru: "По какому адресу мы находимся? ",
    en: "What is our address? ",
    kg: "Биз кайсы даректе жайгашканбыз?"
  },
  "office-address-answer": {
    ru: "г. Бишкек, улица Токтогула, дом 169, офис 19",
    en: "Bishkek city, Toktogul street, Building: 169, Office: 19.",
    kg: "Бишкек шаары,Токтогул көчөсү,169-үй,19-кабинет"
  },
  "activation-code-question": {
    ru: "Я не получил код активации по почте при регистрации, что мне делать?",
    en: "What should I do if I didn't receive the activation code by email upon registration?",
    kg: "Каттоодон өткөндө почта аркылуу активдештирүү кодун алган жокмун, эмне кылышым керек?"
  },
  "activation-code-answer": {
    ru: `Некоторые почтовые серверы кладут письмо с кодом активации в папку "Спам", поэтому если вы не нашли письмо в папке "Входящие" — посмотрите его в папке "Спам".
    Если не помогло, то обратитесь в нашу поддержку в телеграмм @coinxkg`,
    en: "Some email servers may place the activation code email in the 'Spam' folder, so if you haven't found the email in the 'Inbox' folder, please check the 'Spam' folder. If that doesn't help, then contact our support on Telegram @coinxkg.",
    kg: "Кээ бир почта серверлери активдештирүү коду бар катты Спам папкасына коюшат, андыктан катты Кирүүчү кутуңуздан таппасаңыз, аны Спам папкасынан караңыз. Эгер ал жардам бербесе, анда @coinxkg телеграммасы аркылуу биздин колдоо менен байланышыңыз"
  },
  "exchange-duration-question": {
    ru: "Как долго происходит обмен?",
    en: "How long does the exchange take?",
    kg: "Алмашуу канча убакытка созулат?"
  },
  "exchange-duration-answer": {
    ru: "Обмены электронных валют мгновенны. Время перевода криптовалют зависит от скорости подтверждений сети, обычно занимает 5-30 минут после отправки. В случае загруженности сети блокчейн, данное время может увеличится.",
    en: "Electronic currency exchanges are instant. The time it takes to transfer cryptocurrencies depends on the speed of network confirmations, usually taking 5-30 minutes after sending. In the case of blockchain network congestion, this time may increase. In cases where the transaction is already loaded into the network, our service cannot influence the speed of fund transfer.",
    kg: "Электрондук валюта алмаштыруу тез арада. Криптокаражатын которуу убактысы тармак тастыктоо ылдамдыгына жараша болот, адатта жөнөтүү кийин 5-30 мүнөт талап кылынат. Ошондой эле, транзакция вактталганда, биздин кызмат акчаларды жөнөткөн жылдамдыкка таасир көрсөтө албайт."
  },
  "what-is-aml-question": {
    ru: "Что такое AML и для чего он нужен?",
    en: "What is AML and what is it for?",
    kg: "AML деген эмне жана ал эмне үчүн керек?"
  },
  "what-is-aml-answer": {
    ru: "Это - сервис, который обеспечивает анализ и мониторинг криптовалютных транзакций в блокчейне, направленных на предотвращение незаконной деятельности по отмыванию денег. Пользуясь нашим сервисом вы защищаете себя от мошенников и ворованных монет.",
    en: "It is a service that provides analysis and monitoring of cryptocurrency transactions in the blockchain aimed at preventing illegal money laundering activities. By using our service, you protect yourself from scammers and stolen coins.",
    kg: "Бул мыйзамсыз акчаны адалдоо аракеттеринин алдын алууга багытталган блокчейндеги криптовалюталык операцияларды талдоо жана мониторинг жүргүзүүнү камсыз кылган кызмат. Биздин кызматты колдонуу менен сиз өзүңүздү алдамчылардан жана уурдалган тыйындардан коргойсуз."
  },
  "they-trust-us": {
    ru: "(    нам доверяют   )",
    en: "(    they trust us  )",
    kg: "(    алар бизге ишенишет    )"
  },
  "our-partners": {
    ru: "наши партнеры",
    en: "our partners",
    kg: "биздин өнөктөштөр"
  },
  "lets-work-together": {
    ru: "Давайте работать вместе!",
    en: "Let's work together!",
    kg: "Келгиле чогу иштейли"
  },
  phone: {
    ru: "Телефон: ",
    en: "Phone: ",
    kg: "Телефон: "
  },
  email: {
    ru: "Почта: ",
    en: "Email: ",
    kg: "Почта: "
  },
  "our-office": {
    ru: "Наш офис",
    en: "Our office",
    kg: "Биздин кеңсе"
  },
  "our-office-text": {
    ru: [
      "ООО “CoinX”",
      "Бишкек, улица Токтогула, 169, офис 19",
      "Дата начала: 22.06.2023",
      "Регистрационный номер: 219232-3301-OOO",
      "ИНН: 02206202310123",
      "Телефон: +996 (997) 777 500",
      "Почта: team@coinx.kg"
    ],
    en: [
      "CoinX LLC",
      "Bishkek, Toktogul street, 169, office 19",
      "Start date: 06/22/2023",
      "Registration number: 219232-3301-OOO",
      "TIN: 02206202310123",
      "Phone: +996 (997) 777 500",
      "Mail: team@coinx.kg"
    ],
    kg: [
      "OOO CoinX",
      "Бишкек ш., Токтогул көчөсү, 169, 19-кабинет",
      "Башталган датасы: 22.06.2023",
      "Каттоо номери: 219232-3301-ООО",
      "ИИН: 02206202310123",
      "Телефон: +996 (997) 777 500",
      "Почта: team@coinx.kg"
    ]
  },
  "legal-info": {
    ru: ["Юридическая", "Информация о компании"],
    en: ["Legal", "Information about the company"],
    kg: ["Компаниябыз жөнүндө", " Юридикалык маалымат"]
  },
  "legal-info-rows": {
    ru: [
      "Политика конфиденциальности",
      "Публичная оферта",
      "Условия оплаты и возврата",
      "Использование файлов cookie",
      "Политика AML/KYC",
      "Лицензия"
    ],
    en: [
      "Privacy Policy",
      "Public Offer",
      "Payment and Refund Policy",
      "Cookie Usage",
      "AML/KYC Policy",
      "License"
    ],
    kg: [
      "Конфиденциальностика саясат",
      "Коомдук сунуш",
      "Төлөм жана кайтаруу шарттары",
      "Кукилерди колдонуу",
      "AML/KYC Саясаты",
      "Лицензия"
    ]
  },
  "additional-features": {
    ru: [
      "Низкая комиссия",
      "Чистые рубли",
      "Консультант",
      "Партнерские ссылки: ставки для изучения"
    ],
    en: [
      "Low Fee",
      "Clean Rubles",
      "Consultant",
      "Affiliate Links: Rates for Study"
    ],
    kg: [
      "Төмөн комиссия",
      "Таза рубльдер",
      "Кеңешчи",
      "Өнөктөштүк шилтемелер: Изилдөө үчүн ставкалар"
    ]
  }
};
