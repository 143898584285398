import { translate as t } from "../../translations/translations";
import "./partners.css";
import bitgetLogo from "./images/bitget.svg";
import bittraceLogo from "./images/bittrace.svg";
import krakenLogo from "./images/kraken.svg";
import payeerLogo from "./images/payeer.svg";
import voletLogo from "./images/volet.svg";
import bybitLogo from "./images/bybit.svg";
import sumsubLogo from "./images/sumsub.svg";
import helloImg from "./images/hello-man.svg";
import arrowImg from "./images/arrow.svg";
import qrImage from "./images/tg-qr.svg";
import arrowImgHover from "./images/arrow-hover.svg";
import calcImage from "./images/calc-img.svg";

function Partners() {
  const partners = [
    { pic: krakenLogo, link: "https://www.kraken.com/" },
    { pic: bybitLogo, link: "https://www.bybit.com/" },
    { pic: bitgetLogo, link: "https://www.bitget.com/" },
    {
      link: "https://perfectmoney.com/",
      name: "Perfect Money"
    },
    { pic: payeerLogo, link: "https://payeer.com/" },
    { pic: voletLogo, link: "https://volet.com/" },
    { pic: bittraceLogo, link: "https://bittrace.pro/" },
    { pic: sumsubLogo, link: "https://sumsub.com/" },
    { name: "Bakai Bank", link: "https://bakai.kg/" }
  ];
  let lang = localStorage.getItem("coinxLang");
  const signInLink = `https://my.coinx.kg/${lang}/login`;
  return (
    <div className="partners-container">
      <p id="partners-text">{t("they-trust-us")}</p>
      <span id="partners-header">{t("our-partners")}</span>
      <div className="partners">
        {partners.map((partner) => (
          <a href={partner.link} className="partner">
            {partner.pic ? (
              <img src={partner.pic}></img>
            ) : (
              <div className="partner-name">{partner.name}</div>
            )}
          </a>
        ))}
      </div>
      <div className="work-together">
        <div className="work-together-left">
          <div className="hello-image-container">
            <img src={helloImg} className="hello-image"></img>
          </div>
        </div>
        <div className="work-together-right">
          <div className={lang === 'en' ? 'work-together-en' : "work-together-text"}>{t("lets-work-together")}</div>
          <div className="work-together-phone">
            <span className="phone-text">{t("phone")}</span>
            <span className="phone-number"> +996 (997) 777 500</span>
          </div>
          <div className="work-together-email">
            <span className="email-text">{t("email")}</span>
            <span className="email-email"> team@coinx.kg </span>
          </div>
          <div className="work-together-links">
            <a href={signInLink} className="work-together-sign-in">
              <img
                src={arrowImg}
                className="arrow-image arrow-image-white"
              ></img>
              <img
                src={arrowImgHover}
                className="arrow-image arrow-image-black"
              ></img>
              <span>{t("sign-in")}</span>
            </a>
            <a href="https://t.me/coinxkg" className="work-together-qr">
              <img src={qrImage} className="qr-image"></img>
            </a>
            <a
              onClick={() => window.scrollTo({ top: 400, behavior: "smooth" })}
            >
              <img src={calcImage} className="calc-image"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
